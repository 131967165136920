import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './style/reset.scss';
import './style/global.scss';
import HomePage from './Home';

function App() {
  return (
    <BrowserRouter className="App">
      <Routes>
        <Route path="" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
