import { styled } from "styled-components";

export const HomeContainer = styled.div`
  main{
    overflow: hidden;
  }
  section{
    &.p120{
      padding: 120px 0;
    }

    .bg_gray{
      padding: 120px 0;
      background-color: rgba(37, 37, 37, 0.5);
    }

    &.main{
      height: 100vh;
      .slick-slider{
        height: 100%;

        .slide_item{
          height: 100vh;
          .inner{
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .contents{
            text-align: center;
            h3{
              margin: 40px 0 0;
            }
            img{
              width: 160px;
              margin: 40px auto 0;
            }
          }
          &.item1{
            background: url(/images/home_bg1.jpg) no-repeat center center / cover;
          }
          &.item2{
            background: url(/images/home_bg2.jpg) no-repeat center center / cover;
          }
        }
      }

      #particles{
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }

    &.platform{
      .sub_head_text{
        margin: -80px 0 100px;
        text-align: center;
      }
      
      .contents{
        display: flex;
        align-items: center;
        justify-content: center;

        .area_left{
          flex: 1;
          .text p{
            &:nth-child(2){
              max-width: 400px;
              margin: 20px 0 0;
            }
          }
          .group_download{
            margin: 80px 0 50px;
            display: flex;
            align-items: center;

            a{
              width: 140px;
              height: 42px;
              /* width: 160px;
              height: 48px; */
              &.app_store{
                background: url(/images/download_appstore.png) no-repeat center center / cover;
              }
              &.google_play{
                margin: 0 0 0 30px;
                background: url(/images/download_googleplay.png) no-repeat center center / cover;
              }
            }
          }
        }

        .area_right{
          flex: 1;
          .mockup_image{
            width: 100%;
          }
        }
        
      }
    }

    &.blockchain{
      background:  url(/images/bg_blockchain.jpg) no-repeat center center / cover;      
      .group_card{
        display: flex;
        flex-wrap: wrap;
      }
      .card{
        padding: 40px;
        background: rgba(255, 255, 255, .1);
        box-shadow: 0 24px 45px rgba(0, 0, 0, .1);
        border: 1px solid rgba(255, 255, 255, .1);
        border-right: 1px solid rgba(255, 255, 255, .25);
        border-bottom: 1px solid rgba(255, 255, 255, .25);
        border-radius: 24px;
        backdrop-filter: blur(10px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        h5{
          margin: 0 0 20px;
        }
      }
    }

    &.mainnet{
      background: url(/images/bg_mainnet.jpg) no-repeat center center / cover;

      .bg_gray{
        padding: 180px 0;
      }
      img.logo{
        width: 120px;
        margin: 0 auto 40px;
        display: flex;
      }

      .sub_head_text{
        margin: 0 0 20px;
        text-align: center;
      }

      .group_card{
        display: flex;

        .card{
          width: calc((100% - 80px) * 1/3);
          padding: 40px;
          border: 1px solid #fff;
          border-radius: 24px;
          text-align: center;
          display: flex;
          align-items: center;
          justify-content: center;
          + .card{
            margin: 0 0 0 40px;
          }

          &.img{
            background-color: #fff;
            img{
              width: 100%;
            }
          }
        }
      }
    }

    &.hiro1{
      .contents{
        display: flex;
        align-items: center;
      }
      .area{
        &_left{
          .text_list, ol{
            padding-inline-start: 14px;
          }
          .text_list{
            > li{
              max-width: 420px;
              + li{
                margin: 20px 0 0;
              }
            }
            .icon_list{
              margin: 20px 0 0;
              list-style-type: none;
              padding-inline-start: 0;
              display: flex;
              align-items: center;
              li{
                + li{
                  margin: 0 0 0 20px;
                }
                img{
                  width: 40px;
                }
                &:last-child{
                  img{
                    width: 36px;
                  }
                }
              }
            }
          }
          ol{
            li + li{
              margin: 12px 0 0;
            }
          }
        }
        &_right{
          img{
            width: 100%;
          }
        }
      }
    }

    &.hiro2{
      background: url(/images/bg_hiro2.jpg) no-repeat center center / cover;

      .contents{
        display: flex;
        flex-direction: column;
        align-items: center;
        p{
          text-align: center;
          &.cont_txt{
            max-width: 475px;
          }
        }
        h6{
          margin: 20px 0 0;
        }
        .little_legions_img{
          width: 100%;
          margin: 40px 0;
        }
        .group_download{
          margin: 24px 0 0;
          display: flex;
          align-items: center;

          a{
            width: 140px;
            height: 42px;
            border-radius: 4px;
            &.app_store{
              background: #000 url(/images/download_appstore.png) no-repeat center center / cover;
            }
            &.google_play{
              margin: 0 0 0 40px;
              background: #000 url(/images/download_googleplay.png) no-repeat center center / cover;
            }
          }
        }
      }
    }

    &.roadmap{
      background: url(/images/bg_roadmap.jpg) no-repeat center center / cover;

      ul{
        list-style-type: none;
      }

      .row{
        position: relative;
        + .row{
          margin: 60px 0 0;
        }

        &:first-child::before, &:last-child:nth-child(even):before{
          content: '';
          width: 50vw;
          height: 4px;
          background-color: #fff;
          position: absolute;
          left: 0;
        }
        &:last-child:nth-child(odd):before{
          content: '';
          width: 50vw;
          height: 4px;
          background-color: #fff;
          position: absolute;
          right: 0;
        }


        &:nth-child(odd){
          &:not(:last-child){
            .inner::after{
              content: '';
              width: 122px;
              height: 244px;
              background: url(/images/circle_line.png) no-repeat center center / contain;
              position: absolute;
              top: 0;
              right: 44px;
              transform: translate(0, 0) scaleX(-1);
            }
          }
        }
        
        &:nth-child(even){
          .inner{
            flex-direction: row-reverse;
          }

          &:not(:last-child){
            .inner:after{
              content: '';
              width: 122px;
              height: 244px;
              background: url(/images/circle_line.png) no-repeat center center / contain;
              position: absolute;
              top: 0;
              left: 44px;
              transform: translate(0, 0);
            }
          }
        }

        // item 2,3
        &:nth-child(odd) .item:not(:nth-child(1)),
        &:nth-child(even) .item:not(:nth-child(3n)) {
          margin-left: 60px;
          position: relative;
          &:before{
            content: '';
            width: calc(100% + 60px);
            height: 4px;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: -60px;
          }
        }

        // item1
        &:nth-child(odd) .item:nth-child(1),
        &:nth-child(even) .item:nth-child(3n){
          position: relative;
          &:before{
            content: '';
            width: 100%;
            height: 4px;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 0;
          }
        }


        .inner{
          display: flex;
          justify-content: center;
          position: relative;
          .item{
            width: calc((100% - 120px - 244px) * 1/3);
            height: 180px;
            z-index: 1;

            .point{
              width: 24px;
              height: 24px;
              border: 4px solid var(--main);
              border-radius: 50%;
              background-color: #fff;
              transform: translate(0, -50%);
            }

            .text{
              flex: 1;
              padding: 0 0 0 12px;
            }

            .period{
              + p{
                padding: 6px 0 0;
              }
            }
          }
        }
      }
    }

    &.info{
      text-align: center;
      .row{
        display: flex;
        justify-content: center;
        &:not(.group_table){
          h6{
            color: var(--main)
          }
        }

        &.group_table{
          margin: 100px 0 0;
          table{
            margin: 20px 0 0;
            border-radius: 12px;
            background-color: #333;
            border-collapse: collapse;
            box-shadow: rgba(0, 0, 0, .2) 2px 2px 6px 2px;
            overflow: hidden;
            th, td{
              padding: 12px 20px;
            }
            
          }
        }

        .item{
          + .item{
            margin: 0 0 0 120px;
          }
          &:nth-child(1){
            th{
              background-color: #424242;
            }
            td{
              border-top: 1px solid #707070;
            }
            th + th, td + td{
              border-left: 1px solid #707070;
            }
          }
          &:nth-child(2){
            tr + tr td{
              border-top: 1px solid #707070;
            }
            td + td{
              border-left: 1px solid #707070;
            }
          }
        }
      }
    }
  }

  // web
  @media screen and (min-width: 960px) {
    section{
      &.platform{
        .contents{
          .area{
            &_left{
              padding: 0 20px 0 0;
            }
            &_right{
              padding: 0 0 0 20px;
            }
          }
        }
      }

      &.blockchain{
        .card{
          width: calc(100% * 1/3 - 60px);

          &:not(:nth-child(3n + 1)){
            margin-left: 90px;
          }
          &:nth-child(3) ~ .card{
            margin-top: 90px;
          }
        }
      }

      &.mainnet{
        background-attachment: fixed;
      }
      
      &.hiro1{
        .area{
          &_left{
            flex: 1;
            padding: 0 20px 0 0;
          }
          &_right{
            flex: 2;
            padding: 0 0 0 20px;
          }
        }
      }

      &.hiro2{
        background-attachment: fixed;
      }
    }
  }

  // tablet, mobile
  @media screen and (max-width: 959px) {
    section{
      &.mainnet{
        .group_card{
          flex-direction: column;
          align-items: center;
          .card{
            width: 100%;
            max-width: 500px;
            + .card{
              margin: 40px 0 0;
            }
          }
        }
      }

      &.hiro1{
        .contents{
          flex-direction: column;
        }
        .area{
          &_right{
            padding: 40px 0 0;
            img{
              max-width: 760px;
            }
          }
        }
      }
    }
  }
  
  // tablet
  @media screen and (min-width: 768px) and (max-width: 959px) {
    section{
      &.platform{
        .contents{
          .area_left{
            flex: 1;
            padding: 0 20px 0 0;
          }
        }
      }

      &.blockchain{
        .card{
          width: calc((100% - 80px) * 1/3);
          &:not(:nth-child(3n + 1)){
            margin-left: 40px;
          }
          &:nth-child(3) ~ .card{
            margin-top: 40px;
          }
        }
      }

      &.info{
        .row{
          .item + .item{
            margin: 0 0 0 60px;
          }
        }
      }

    }
  }
  
  // mobile
  @media screen and (max-width: 767px) {
    section{
      &.platform{
        .contents{
          flex-direction: column-reverse;
          .area_left{
            display: flex;
            flex-direction: column;
            align-items: center;
            .group_download{
              margin: 60px 0 40px;
              a{
                width: 140px;
                height: 42px;
                &.google_play{
                  margin: 0 0 0 20px;
                }
              }
            }
          }
          .area_right{
            max-width: 400px;
            margin: 0 0 40px;
          }
        }
      }

      &.blockchain{
        .card{
          width: calc((100% - 20px) * 1/2);
          &:not(:nth-child(odd)){
            margin-left: 20px;
          }
          &:nth-child(2) ~ .card{
            margin-top: 20px;
          }
        }
      }

      &.roadmap{
        .contents{
          position: relative;
          &::before{
            content: '';
            width: 4px;
            height: 100%;
            background-color: #fff;
            position: absolute;
            top: 0;
            left: 30px;
          }
        }
        .row{
          &::before{
            display: none;
          }
          + .row{
            margin: 0;
          }

          .inner{
            flex-direction: column !important;
            &::after, &::before{
              display: none !important;
            }
            .item{
              width: 100%;
              height: auto;
              margin: 0 !important;
              padding: 0 0 60px;
              display: flex;
              &::before{
                display: none;
              }
              .point{
                transform: translate(0, 0);
              }
            }
          }
        }
      }

      &.info{
        .row{
          flex-direction: column;
          &.group_table{
          }

          .item{
            display: flex;
            flex-direction: column;
            align-items: center;
            + .item{
              margin: 60px 0 0;
            }
          }
        }
      }

    }
  }

  // mobile
  @media screen and (max-width: 500px) {
    section{
      &.blockchain{
        .card{
          width: 100%;
          &:not(:nth-child(odd)){
            margin-left: 0;
          }
          &:nth-child(1) ~ .card{
            margin-top: 20px;
          }
        }
      }
    }
  }
`

export const HeaderContainer = styled.header`
  width: 100%;
  height: 80px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 10;

  &.scroll{
    background-color: rgba(0, 0, 0, 0.75);
    box-shadow: 0 6px 15px 3px rgba(0, 0, 0, 0.1);
  }

  .mw1280{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo_btn{
      width: 140px;
      img{
        width: 100%;
      }
    }
  }

  .btn_group{
    display: flex;
    align-items: center;
  }
  .nav_btn{
    height: 100%;
    display: flex;
    align-items: center;
    background-color: transparent;
    text-transform: uppercase;
  }
  .gitbook_btn{
    height: 48px;
    padding: 0 14px;
    border-radius: 6px;
    background-color: var(--main);
    line-height: 48px;
  }
  .apps{
    position: relative;
    .app_btn{
      width: 48px;
      height: 48px;
      border-radius: 50%;
      background-color: #222;
      display: flex;
      align-items: center;
      justify-content: center;
      img{
        width: 16px;
        height: 16px;
      }
    }

    .card{
      width: 0;
      height: 100%;
      background: #000;
      position: fixed;
      top: 80px;
      right: 0;
      transition: all .25s ease;
      z-index: 2;

      &:not(.open){
        opacity: 0;
      }
      &.open{
        width: 480px;
      }

      .head{
        padding: 20px 30px 0;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .btn_close{
        width: 48px;
        height: 48px;
        margin: 0 -16px 0 0;
        background: url(/images/icon_clear.png) no-repeat center center / 16px 16px;
      }

      ul{
        list-style-type: none;
        padding-inline-start: 0;
        &.first{
          padding: 20px 30px;
          li.first_item{
            border-bottom: 1px solid #fff;
            &:nth-child(1){
              border-top: 1px solid #fff;
            }

            .btn_dd{
              width: 100%;
              padding: 15px 20px;
              background-color: transparent;
              text-align: left;
              display: flex;
              align-items: center;
              position: relative;
              &::before{
                content: '';
                width: 16px;
                height: 16px;
                background: url(/images/icon_arrow.png) no-repeat center center / cover;
                display: block;
                position: absolute;
                top: calc(50% - 8px);
                right: 0;
                transition: all .25s ease;
              }

              &.active{
                &::before{
                  transform: rotate(180deg);
                }
              }

              img{
                width: 16px;
                margin: 0 12px 0 0;
              }
            }

            &.dangnn{
              .second{
                li{
                  .img{
                    padding: 4px;
                    background-color: white;
                  }
                }
              }
            }
          }
        }

        &.second{
          max-height: 0;
          overflow: hidden;
          &:not(.show){
            overflow: hidden;
            transition: max-height .2s ease-in-out;
          }
          &.show{
            max-height: 100vh;
            transition: max-height .5s ease-in-out;
          }
          li{
            &:last-child{
              margin: 0 0 12px;
            }
            a{
              padding: 10px 20px;
              display: flex;
              align-items: center;
              .img{
                width: 50px;
                height: 50px;
                margin: 0 15px 0 0;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                img{
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }

  .menu_btn{
    width: 60px;
    height: 60px;
    margin-right: -15px;
    background-color: transparent;
  }

  @media screen and (min-width: 960px) {
    .btn_group{
      height: 100%;
    }

    .nav_btn{
      + .nav_btn{
        margin: 0 0 0 16px;
      }
    }
    .gitbook_btn{
      margin: 0 0 0 16px;
    }

    .apps{
      margin: 0 0 0 16px;
    }
  }

  @media screen and (max-width: 959px) {
    height: 60px;
    .mw1280{
      position: relative;
      .logo_btn{
        width: 120px;
        img{
          width: 100%;
        }
      }
    }
    .btn_group{
      min-width: 240px;
      padding: 0 0 10px;
      border: 1px solid #666;
      border-radius: 12px;
      background: rgba(0, 0, 0, .8);
      box-shadow: 0 24px 45px rgba(0, 0, 0, .1);
      flex-direction: column;
      position: absolute;
      top: 60px;
      right: 20px;
      display: none;
      &.show{
        display: flex;
      }
    }
    .nav_btn{
      height: 48px;
      line-height: 48px;
      + .nav_btn{
        margin: 10px 0 0;
      }
    }

    .gitbook_btn{
      margin: 10px 0;
    }

    .apps{
      .card{
        top: 0;
        
        &.open{
          width: 100%;
        }
      }
    }

    .menu_btn{
      position: relative;
      overflow: hidden;
      span{
        display: block;
        width: 24px;
        height: 2px;
        border-radius: 2px;
        background-color: #fff;
        position: absolute;
        top: 50%;
        left: 50%;
        transition: all .5s;

        &:nth-child(1){
          transform: translate(-50%, -8px);
        }
        &:nth-child(2){
          transform: translate(-50%, 0);
        }
        &:nth-child(3){
          transform: translate(-50%, 8px);
        }
      }

      &.active span{
        &:nth-child(1){
          transform: translate(-50%, 0) rotate(45deg);
        }
        &:nth-child(2){
          transform: translate(60px, 0);
        }
        &:nth-child(3){
          transform: translate(-50%, 0) rotate(-45deg);
        }
      }
    }
  }
`

export const FooterContainer = styled.footer`
  padding: 80px 0;
  background-color: #363636;
  .inner{
    display: flex;
    align-items: center;
    img{
      width: 60px;
    }

    .area_txt{
      flex: 1;
      padding: 0 0 0 80px;
      &_top{
        padding: 0 0 40px;
        display: flex;
        .top{
          &_left{
            h6{
              margin: 0 0 40px;
            }
            p{
              font-size: 12px;
              + p{
                margin: 10px 0 0;
              }
            }
          }
          &_right{
            padding: 0 0 0 120px;
            h6{
              margin: 0 0 10px;
            }
            .group_community{
              margin: 40px 0 0;
              display: flex;
              align-items: center;
              a{
                width: 36px;
                height: 36px;
                &.twitter{
                  background: url(/images/sns_twitter.png) no-repeat center center / cover;
                }
                &.telegram{
                  margin: 0 0 0 20px;
                  background: url(/images/sns_telegram.png) no-repeat center center / cover;
                }
                &.medium{
                  margin: 0 0 0 20px;
                  background: url(/images/sns_medium.png) no-repeat center center / cover;
                }
              }
            }
          }
        }
      }
      .copyright{
        padding: 10px 0 0;
        border-top: 1px solid #666;
        font-size: 12px;
      }
    }
  }


  // tablet
  @media screen and (min-width: 768px) and (max-width: 959px) {
  }

  // mobile
  @media screen and (max-width: 767px) {
    padding: 40px 0;
    .inner{
      flex-direction: column;
      .area_txt{
        padding: 0;
        &_top{
          flex-direction: column;
          .top_right{
            padding: 40px 0 0;
          }
        }
      }
    }
  }
`