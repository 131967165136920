import { Link, useLocation } from "react-router-dom";
import { FooterContainer, HeaderContainer, HomeContainer } from "./style/home";

import { MdApps } from "react-icons/md";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useEffect, useRef, useState } from "react";

import AOS from "aos";
import "aos/dist/aos.css";
import ParticlesComponent from "./components/particles";

export default function HomePage() {
  const location = useLocation();
  const [isScroll, setIsScroll] = useState(false);
  const [showDropdownMenu, setShowDropDownMenu] = useState(false);
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [activeAppsMenu, setActiveAppsMenu] = useState(0);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [screenType, setScreenType] = useState('web');
  
  const homeRef = useRef(null);
  const platformRef = useRef(null);
  const dgcRef = useRef(null);
  const mainnetRef = useRef(null);
  const dappRef = useRef(null);
  const roadmapRef = useRef(null);
  const infoRef = useRef(null);

  const settings = {
    dots: false,
    arrows: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };
  
  useEffect(() => {
    window.addEventListener("scroll", handleScroll)
  });

  useEffect(() => {
    AOS.init();

    const handleResize = () => {
      setScreenWidth(window.innerWidth)
    }
    window.addEventListener("resize", handleResize);
  }, [])

  useEffect(() => {
    if(screenWidth >= 960) {
      setScreenType('web');
    } else if(screenWidth >= 768 && screenWidth < 960){
      setScreenType('tablet');
    } else{
      setScreenType('mobile');
    }
  }, [screenWidth])

  useEffect(() => {
    setShowDropDownMenu(false);
  }, [location])

  const handleScroll = () => {
    if (window.scrollY > 60) {
      setIsScroll(true)
    } else {
      setIsScroll(false)
    }
  };

  const onClickHome = () => {
    homeRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowMobileMenu(false);
  }
  const onClickPlatform = () => {
    platformRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowMobileMenu(false);
  }
  const onClickDgc = () => {
    dgcRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowMobileMenu(false);
  }
  const onClickMainnet = () => {
    mainnetRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowMobileMenu(false);
  }
  const onClickDapp = () => {
    dappRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowMobileMenu(false);
  }
  const onClickRoadmap = () => {
    roadmapRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowMobileMenu(false);
  }
  const onClickInfo = () => {
    infoRef.current.scrollIntoView({ behavior: 'smooth' });
    setShowMobileMenu(false);
  }

  return (
    <HomeContainer className={`${screenType}`}>
      <HeaderContainer className={`${isScroll ? 'scroll' : ''}`}>
        <div className="mw1280">
          <Link to="/" className="logo_btn">
            <img src="/images/logo_row.png" alt="dangnn" />
          </Link>
            <div className={`btn_group ${showMobileMenu ? 'show' : ''}`}>
              <button className="nav_btn" onClick={onClickHome}>home</button>
              <button className="nav_btn" onClick={onClickPlatform}>platform</button>
              <button className="nav_btn" onClick={onClickDgc}>dgc</button>
              <button className="nav_btn" onClick={onClickMainnet}>mainnet</button>
              <button className="nav_btn" onClick={onClickDapp}>dapp</button>
              <button className="nav_btn" onClick={onClickRoadmap}>roadmap</button>
              <button className="nav_btn" onClick={onClickInfo}>coin info</button>
              <Link to="https://dangnncoin.gitbook.io/dangnn-coins-white-paper" target="_blank" className="gitbook_btn" onClick={e => setShowMobileMenu(false)}>Gitbook</Link>
              <div className="apps">
                <button className="app_btn"
                  onClick={e => setShowDropDownMenu(true)}
                >
                  <img src="/images/icon_apps.png" alt="apps" />
                </button>

                <div className={`card ${showDropdownMenu ? 'open' : ''}`}>
                  <div className="head">
                    <h6 className="bold">DANGNN Apps</h6>
                    <button
                      className="btn_close"
                      onClick={e => {
                        setShowDropDownMenu(false)
                        setActiveAppsMenu(0)
                      }}
                    />
                  </div>
                  <ul className="first">
                    <li className="first_item dangnn">
                      <button className={`btn_dd ${activeAppsMenu === 1 ? 'active' : ''}`}
                        onClick={e => setActiveAppsMenu(activeAppsMenu === 1 ? 0 : 1)}
                      >
                        <img src="/images/apps/apps_ic1.png" alt="DANGNN Main-net" />
                        <p>DANGNN Main-net</p>
                      </button>
                      <ul className={`second ${activeAppsMenu === 1 ? 'show' : ''}`}>
                        <li>
                          <Link to="https://explorer.dangnn.co.kr/account-detail/0x16a4b9ff5ed5cafcfe40276142cc71643429d072/tab/transactions" target="_blank">
                            <div className="img">
                              <img src="/images/logo.png" alt="Main-net explorer" />
                            </div>
                            <h6 className="bold">Main-net explorer</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://dangnncoin.com/" target="_blank">
                          <div className="img">
                            <img src="/images/logo.png" alt="DANGNN Homepage Token" />
                          </div>
                            <h6 className="bold">DANGNN Homepage Token</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://dangnndefi.com/" target="_blank">
                            <div className="img">
                              <img src="/images/logo.png" alt="Dangnndefi" />
                            </div>
                            <h6 className="bold">Dangnndefi</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://wallet.dangnndefi.com/" target="_blank">
                            <div className="img">
                              <img src="/images/logo.png" alt="Dangnn Wallet Web" />
                            </div>
                            <h6 className="bold">Dangnn Wallet Web</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="" target="_blank">
                            <div className="img">
                              <img src="/images/logo.png" alt="Dangnn Wallet Play Store" />
                            </div>
                            <h6 className="bold">Dangnn Wallet Play Store</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://apps.apple.com/kr/app/dangnn-wallet/id6499183072" target="_blank">
                            <div className="img">
                              <img src="/images/logo.png" alt="Dangnn Wallet App Store" />
                            </div>
                            <h6 className="bold">Dangnn Wallet App Store</h6>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="first_item hiro">
                      <button className={`btn_dd ${activeAppsMenu === 2 ? 'active' : ''}`}
                        onClick={e => setActiveAppsMenu(activeAppsMenu === 2 ? 0 : 2)}
                      >
                        <img src="/images/apps/apps_ic2.png" alt="HIRO Platform" />
                        <p>HIRO Platform</p>
                      </button>
                      <ul className={`second ${activeAppsMenu === 2 ? 'show' : ''}`}>
                        <li>
                          <Link to="https://hironheroes.com/page/game.php" target="_blank">
                            <div className="img">
                              <img src="/images/apps/hiro_token.png" alt="HIRO Homepage" />
                            </div>
                            <h6 className="bold">HIRO Homepage</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://hiro-token.com/" target="_blank">
                            <div className="img">
                              <img src="/images/apps/hiro_token.png" alt="HIRO Token" />
                            </div>
                            <h6 className="bold">HIRO Token</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://play.google.com/store/apps/details?id=com.UFirst.HiroWalletApp" target="_blank">
                            <div className="img">
                              <img src="/images/apps/hiro_app.png" alt="HIRO Wallet Play Store" />
                            </div>
                            <h6 className="bold">HIRO Wallet Play Store</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://apps.apple.com/kr/app/hiro-wallet/id6475075373" target="_blank">
                            <div className="img">
                              <img src="/images/apps/hiro_app.png" alt="HIRO Wallet App Store" />
                            </div>
                            <h6 className="bold">HIRO Wallet App Store</h6>
                          </Link>
                        </li>
                      </ul>
                    </li>

                    <li className="first_item legions">
                      <button className={`btn_dd ${activeAppsMenu === 3 ? 'active' : ''}`}
                        onClick={e => setActiveAppsMenu(activeAppsMenu === 3 ? 0 : 3)}
                      >
                        <img src="/images/apps/apps_ic3.png" alt="Little Legions" />
                        <p>Little Legions</p>
                      </button>
                      <ul className={`second ${activeAppsMenu === 3 ? 'show' : ''}`}>
                        <li>
                          <Link to="https://play.google.com/store/apps/details?id=com.TheCove.LittleLegions.AOS.Global&hl=ko&gl=US" target="_blank">
                            <div className="img">
                              <img src="/images/apps/little_legions_app.png" alt="Little Legions Play Store" />
                            </div>
                            <h6 className="bold">Little Legions Play Store</h6>
                          </Link>
                        </li>
                        <li>
                          <Link to="https://apps.apple.com/us/app/little-legions-nft/id1661103987?mt=8" target="_blank">
                            <div className="img">
                              <img src="/images/apps/little_legions_app.png" alt="Little Legions App Store" />
                            </div>
                            <h6 className="bold">Little Legions App Store</h6>
                          </Link>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

          {screenType !== 'web' && (
            <button className={`menu_btn ${showMobileMenu ? 'active' : ''}`} 
              onClick={e => setShowMobileMenu(!showMobileMenu)}
            >
              <span />
              <span />
              <span />
            </button>
          )}
        </div>
      </HeaderContainer>

      <main>
        <section className="main" ref={homeRef}>
          <Slider {...settings}>
            <div className='slide_item item1'>
              <div className="mw1280 inner">
                <div className="contents">
                  <h1>DGC PROJECT</h1>
                  <h3>P2E Global No.1 platform</h3>
                  <img src="/images/logo_col.png" alt="dangnn logo" />
                </div>
              </div>
            </div>
            <div className='slide_item item2'>
              <div className="bg_gray">
                <div className="mw1280 inner">
                  <div className="contents">
                    <h1>Blockchain Project</h1>
                    <img src="/images/logo_col.png" alt="dangnn logo" />
                  </div>
                </div>
              </div>
            </div>
          </Slider>

          <ParticlesComponent id="particles" />
        </section>
        
        <section className="platform p120" ref={platformRef}>
          <div className="inner mw1280">
            <h2 className="section_head">Platform</h2>
            <h6 className="sub_head_text">Wallet + defi</h6>
            <div className="contents">
              <div className="area_left">
                <div
                  className="text"
                  data-aos="fade-down-right"
                  data-aos-easing="linear"
                  data-aos-duration="1000"
                >
                  <p>DANGNN Mainnet tokens, coins, and tokens can be easily managed.</p>
                  <p>Coins and tokens issued by DANGNN Mainnet, some coins and tokens that you already have, are easily managed through DANGNN Wallet, and various functions such as swaps, transfers, and NFT purchases are supported.</p>
                </div>
                <div className="group_download">
                  <Link to="https://apps.apple.com/kr/app/dangnn-wallet/id6499183072" className="app_store"
                    target="_blank"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  />
                  <Link to="" className="google_play"
                    target="_blank"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  />
                </div>
              </div>

              <div className="area_right">
                <img src="/images/platform_mockup.png" alt="platform" 
                  className="mockup_image"
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </section>
        
        <section className="blockchain" ref={dgcRef}>
          <div className="bg_gray">
            <div className="inner mw1280">
              <h2 className="section_head">DGC Blockchain</h2>
              <div className="group_card">
                <div className="card"
                  data-aos="fade-up"
                  data-aos-duration="1000"
                >
                  <h5 className="bold">Private Blockchain</h5>
                  <p>Only blockchain node participants can become important members of the DGC project.</p>
                </div>
                <div className="card"
                  data-aos="fade-up"
                  data-aos-delay="50"
                  data-aos-duration="1000"
                >
                  <h5 className="bold">Public Blockchain</h5>
                  <p>Blockchain node participants receive rewards for mining based on the quantity of nodes they possess.</p>
                </div>
                <div className="card"
                  data-aos="fade-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <h5 className="bold">Hybrid Function</h5>
                  <p>The DGC blockchain allows for unrestricted project participation based on the quantity of staked nodes.</p>
                </div>
                <div className="card"
                  data-aos="fade-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                >
                  <h5 className="bold">POW+A</h5>
                  <p>Evolved Proof of Work through the application of mining rewards halving based on the quantity of nodes.</p>
                </div>
                <div className="card"
                  data-aos="fade-up"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  <h5 className="bold">How to Mine</h5>
                  <p>Participate in mining. Mining is the only way to participate in this project.</p>
                </div>
                <div className="card"
                  data-aos="fade-up"
                  data-aos-delay="250"
                  data-aos-duration="1000"
                >
                  <h5 className="bold">Half-Life</h5>
                  <p>The application of mining rewards halving based on the increase in the quantity of nodes is a crucial project for participants’ earnings.</p>
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="mainnet" ref={mainnetRef}>
          <div className="bg_gray">
            <div className="inner mw1280">
              <img src="/images/logo.png" alt="dangnn logo" className="logo" />
              <p className="sub_head_text">Now Working For Better Landing Site.</p>
              <h2 className="section_head">DANGNN mainnet</h2>
              <div className="group_card">
                <div className="card"
                  data-aos="zoom-in-up"
                  data-aos-delay="100"
                  data-aos-duration="1000"
                >
                  <p>DGC coins are distributed as rewards to those who participated in the formation of the mainnet blockchain. All rewards within the blockchain continue to be carried out through DGC coins.</p>
                </div>
                <div className="card img"
                  data-aos="zoom-in-up"
                  data-aos-delay="150"
                  data-aos-duration="1000"
                >
                  <img src="/images/dangnn_mainnet_img.png" alt="dangnn logo" />
                </div>
                <div className="card"
                  data-aos="zoom-in-up"
                  data-aos-delay="200"
                  data-aos-duration="1000"
                >
                  <p>DGC coin is a project coin that supports the blockchain. The DGC mainnet blockchain supports unique voting rights and provides ongoing rewards through participation in blockchain node activities. Additionally, it supports POW A through a POW system upgrade.</p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="hiro1 p120" ref={dappRef}>
          <div className="inner mw1280">
            <h2 className="section_head">HIRO(HRT) Token<br/>HIRO Wallet</h2>
            <div className="contents">
              <div className="area_left">
                <ul className="text_list"
                  
                >
                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="600"
                  >HiroToken Powered by DANGNN Mainnet</li>
                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="600"
                    data-aos-delay="50"
                  >HIRO Wallet supports DGC, HRT asset management and transfer functions and more.
                    <ul className="icon_list">
                      <li><img src="/images/hiro-icon1.png" alt="hiro icon" /></li>
                      <li><img src="/images/hiro-icon2.png" alt="hiro icon" /></li>
                      <li><img src="/images/hiro-icon3.png" alt="hiro icon" /></li>
                      <li><img src="/images/hiro-icon4.png" alt="hiro icon" /></li>
                    </ul>
                  </li>
                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="600"
                    data-aos-delay="100"
                  >Littele LEGIONS game linkage</li>
                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="600"
                    data-aos-delay="150"
                  >More global games coming soon</li>
                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="600"
                    data-aos-delay="200"
                  >Will be used to purchase game NFTs</li>
                </ul>
                <ol>
                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="600"
                    data-aos-delay="250"
                  >convert COV points to COV tokens</li>
                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="600"
                    data-aos-delay="300"
                  >from COV to HIRO tokens</li>
                  <li
                    data-aos="fade-up"
                    data-aos-easing="ease-out-cubic"
                    data-aos-duration="600"
                    data-aos-delay="350"
                  >send HIRO tokens to MEXC market</li>
                </ol>
              </div>

              <div className="area_right">
                <img src="/images/hiro_mockup.png" alt="hiro apps" 
                  className="mockup_image"
                  data-aos="zoom-in-left"
                  data-aos-duration="2000"
                />
              </div>
            </div>
          </div>
        </section>

        <section className="hiro2">
          <div className="bg_gray">
            <div className="inner mw1280">
              <h2 className="section_head">Littele LEGIONS game linkage</h2>
              <div className="contents">
                <p
                  className="cont_txt"
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="600"
                >Get ready to embark on an extraordinary adventure filled with epic battles, strategic gameplay, and the thrilling Play-to-Earn (P2E) experience you love.</p>
                <h6 className="bold"
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="600"
                >Hurry up and be a part of this remarkable journey that awaits you!</h6>
                <img src="/images/little-legions.png" alt="little-legions"
                  className="little_legions_img"
                  data-aos="zoom-in"
                  data-aos-duration="700"
                  data-aos-easing="ease-out-cubic"
                  data-aos-delay="300"
                />
                <h5 className="bold"
                  data-aos="fade-up"
                  data-aos-easing="ease-out-cubic"
                  data-aos-duration="800"
                >Download Now</h5>
                <div className="group_download">
                  <Link to="https://apps.apple.com/us/app/little-legions-nft/id1661103987?mt=8" className="app_store"
                    target="_blank"
                    data-aos="fade-up"
                    data-aos-delay="50"
                    data-aos-duration="1000"
                  />
                  <Link to="https://play.google.com/store/apps/details?id=com.TheCove.LittleLegions.AOS.Global&hl=ko&gl=US" className="google_play"
                    target="_blank"
                    data-aos="fade-up"
                    data-aos-delay="100"
                    data-aos-duration="1000"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
        
        <section className="roadmap" ref={roadmapRef}>
          <div className="bg_gray">
            <h2 className="section_head">ROADMAP</h2>
            <div className="contents">
              <div className="row">
                <ul className="inner mw1280">
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2020. 3Q</h6>
                      <p>Establishment of Genesis Fin</p>
                    </div>
                  </li>
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-delay="50"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2020. 4Q</h6>
                      <p>Mainnet development(POW+A) distribution of the platform services</p>
                    </div>
                  </li>
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-delay="100"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2021. 3Q</h6>
                      <p>Started develop DANGNNDAYA platform Application Planning the P2E business</p>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="row">
                <ul className="inner mw1280">
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-delay="150"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2022. 1Q</h6>
                      <p>Release DANGNNDAYA test net signing the Mou with ‘LG hellovision’</p>
                    </div>
                  </li>
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-delay="200"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2022. 3Q</h6>
                      <p>list on domestic dan foreign exchanges (undetermined)</p>
                    </div>
                  </li>
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-delay="250"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2022. 4Q</h6>
                      <p>P2E business plan Establishment</p>
                    </div>
                  </li>
                </ul>
              </div>
              
              <div className="row">
                <ul className="inner mw1280">
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-delay="300"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2023. 1Q</h6>
                      <p>Planning to develop a P2E system scheduling for development of mobile payment eco systems</p>
                    </div>
                  </li>
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-delay="350"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2023. 4Q</h6>
                      <p>DANGNNDAYA platform expansion open Proceed DANGNNDAYA mainnet-based token issuance business</p>
                    </div>
                  </li>
                  <li className="item">
                    <div className="point" />
                    <div className="text"
                      data-aos="zoom-in-up"
                      data-aos-delay="400"
                      data-aos-duration="1000"
                    >
                      <h6 className="period">2024. 1~2Q</h6>
                      <p>Release DANGNN Defi Wallet spsp</p>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
        
        <section className="info p120" ref={infoRef}>
          <div className="inner mw1280">
            <h2 className="section_head">COIN Information</h2>
            <div className="contents">
              <div className="row">
                <div className="item">
                  <p>Symbol</p>
                  <h6 className="bold">DGC</h6>
                </div>
                <div className="item">
                  <p>Full Name</p>
                  <h6 className="bold">DANGNN DAYA COIN</h6>
                </div>
                <div className="item">
                  <p>Development-based Tech</p>
                  <h6 className="bold">DGC Project Mainnet</h6>
                </div>
              </div>

              <div className="row group_table">
                <div className="item">
                  <h5 className="bold">Mining and Miner Information</h5>
                  <table>
                    <thead>
                      <tr>
                        <th>Period</th>
                        <th>Total Mining per Year</th>
                        <th>Max. Number of Miners</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>1 Year</td>
                        <td>816,000,000</td>
                        <td>16,667</td>
                      </tr>
                      <tr>
                        <td>2 Year</td>
                        <td>816,000,000</td>
                        <td>16,667</td>
                      </tr>
                      <tr>
                        <td>3 Year</td>
                        <td>816,000,000</td>
                        <td>16,667</td>
                      </tr>
                      <tr>
                        <td>4 Year</td>
                        <td>408,000,000</td>
                        <td>Unlimited</td>
                      </tr>
                      <tr>
                        <td>5 Year</td>
                        <td>408,000,000</td>
                        <td>Unlimited</td>
                      </tr>
                      <tr>
                        <td>6 Year</td>
                        <td>408,000,000</td>
                        <td>Unlimited</td>
                      </tr>
                      <tr>
                        <td>7 Year</td>
                        <td>204,000,000</td>
                        <td>Unlimited</td>
                      </tr>
                      <tr>
                        <td>8 Year</td>
                        <td>204,000,000</td>
                        <td>Unlimited</td>
                      </tr>
                      <tr>
                        <td>9 Year</td>
                        <td>204,000,000</td>
                        <td>Unlimited</td>
                      </tr>
                      <tr>
                        <td>10 Year</td>
                        <td>102,000,000</td>
                        <td>Unlimited</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div className="item">
                  <h5 className="bold">Distribution Information</h5>
                  <table>
                    <tbody>
                      <tr>
                        <td>Presale</td>
                        <td>1 bil DGC</td>
                      </tr>
                      <tr>
                        <td>Mining Compensation	</td>
                        <td>6.5 bil DGC</td>
                      </tr>
                      <tr>
                        <td>Marketing Airdrop	</td>
                        <td>1 bil DGC</td>
                      </tr>
                      <tr>
                        <td>Foundation Holdings	</td>
                        <td>1 bil DGC</td>
                      </tr>
                      <tr>
                        <td>Worldwide Develovpers</td>
                        <td>500 mil DGC</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <FooterContainer>
        <div className="inner mw1280">
          <img src="/images/logo.png" alt="dangnn logo" />

          <div className="area_txt">
            <div className="area_txt_top">
              <div className="top_left">
                <h6>NS PAYMENTS.</h6>
                <p>CEO Frank S KIM</p>
                <p>Address 16 RAFFLES QUAY #33-07 HONG LEONG BUILDING SINGAPORE (048581)</p>
                <p>Company Registration Number 202144626H</p>
              </div>
              <div className="top_right">
                <h6 className="bold">Contact us</h6>
                <p>contact@dangnncoin.com</p>
                <div className="group_community">
                  <Link to="https://twitter.com/DangnnOFFICIAL" target="_blank" className="twitter" />
                  <Link to="https://t.me/dgcglobalcommunity" target="_blank" className="telegram" />
                  <Link to="https://medium.com/@DGCOfficial" target="_blank" className="medium" />
                </div>
              </div>
            </div>
            <p className="copyright">@COPYRIGHT  DANGNN. ALL RIGHTS RESERVED.</p>
          </div>
        </div>
      </FooterContainer>
    </HomeContainer>
  )
}